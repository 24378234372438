.Question {
    display: flex;
    align-self: center;
    flex-flow: column;
    position: relative;
    margin: auto;
    flex: 1 1 100%;
    width: 60%;
    justify-content: center;
    align-items: center;
    min-width: 280px;
}

.Label {
    display: flex;
    font-size: 0.875rem;
    position: absolute;
    top: 10%;
    height: 1rem;
    transition: all 100ms linear;

    transform: translate(0, 1rem) scale(1.2);
    transform-origin: left center;
    pointer-events: none;
}

input:focus + .Label,
input:active + .Label,
.LabelWithValue {
    font-size: 1rem;
    transform: translate(0, -2rem);
}

.Info {
    position: absolute;
    top: 4.4rem;
    font-size: 14px;
    left: 0;
    color: #848484;
    text-align: left;
}

.FieldBlock {
    display: flex;
    flex-flow: column;
    position: relative;
    width: 100%;
}

.Field {
    height: 3rem;
    font-size: 1rem;
    padding: 0.5rem;
    background: transparent;
    outline: none;
    border-width: 0px 0px 2px 0px;
    border-color: rgba(0, 0, 0, 0.2);
    color: #fff;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {

}

.FieldDate {
}

.FieldText {
}

.FieldNumeric {
}

.FieldInvalid {
    border-color: #ff9999;
}

.FieldRadio {
    display: flex;
    flex-flow: column nowrap;
}

.FieldRadioItem {
    display: flex;
    flex-flow: row nowrap;
    text-align: left;
    font-size: 1rem;
    margin-bottom: 1rem;
}

.RadioFieldContainer {
    display: flex;
    position: relative;
    width: 2rem;
    height: 2rem;
    flex: 0 0 2rem;
    align-items: center;
    justify-content: center;
}

.RadioStyle {
    display: flex;
    background: #fff;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    position: relative;
    border: 4px solid #4a50af;
    opacity: 0.7;
    transition: all 100ms linear;
}

.RadioStyleInner {
    position: absolute;
    display: flex;
    background: #4a50af;
    border-radius: 50%;
    transform-origin: center center;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0%;
    transform: scale(0, 0);
    transition: all 100ms linear;
}

.RadioField:checked + .RadioStyle {
    opacity: 1;
}

.RadioField:checked + .RadioStyle .RadioStyleInner {
    transform: scale(0.7, 0.7);
}

.RadioLabel {
    display: flex;
    align-self: center;
}

.FieldRadioItem {
    font-size: 0.75rem;
}

.RadioField {
    position: absolute;
    opacity: 0;
}

.FieldRadio ~ .Label {
    top: -3rem;
    transform: translate(0, 1rem) scale(1);
    font-size: 0.875rem;
}

.FieldCheckbox ~ .Label {
    top: -5rem;
    transform: translate(0, 1rem) scale(1);
    font-size: 0.875rem;
}

.FieldRadio ~ .Info,
.FieldCheckbox ~ .Info {
    display: none;
}

.FieldCheckboxItem {
    display: flex;
    flex-flow: row nowrap;
}

.CheckboxFieldContainer {
    display: flex;
    width: 4rem;
    height: 4rem;
    justify-content: center;
    align-items: center;
}

.CheckboxField {
    position: absolute;
    opacity: 0;
}

.CheckboxStyle {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 4px;
    border: 1px solid #eee;
    background: #eee;
    position: relative;
    opacity: 0.7;
    transition: all 100ms linear;
}

.CheckboxStyleInner {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: #4a50af;
    transform: scale(0, 0);
    transition: all 100ms linear;
}

.CheckboxField:checked ~ .CheckboxStyle {
    opacity: 1;
}

.CheckboxField:checked ~ .CheckboxStyle .CheckboxStyleInner {
    transform: scale(0.7, 0.7);
}

.CheckboxLabel {
    display: flex;
    text-align: left;
    align-items: center;
    font-size: 0.875rem;
}

.FieldSecondaryField {
    display: flex;
    flex-flow: column;
    padding: 0 1rem;
    margin-top: 1rem;
    position: relative;
    font-size: 0.875rem;
}

.FieldSecondaryFieldLabel,
.FieldSecondaryFieldField {
    display: flex;
}

.FieldSecondaryFieldLabel {
    font-size: 14px;
    position: absolute;
    bottom: -1rem;
}

.FieldSecondaryFieldField {
    display: flex;
    width: 100%;
    background: transparent;

    height: 3rem;
    font-size: 1rem;
    padding: 0.5rem;
    outline: none;
    border-width: 0px 0px 2px 0px;
    border-color: rgba(0, 0, 0, 0.2);
    color: #fff;
}

.ConfirmWrapper {
    display: flex;
    flex-flow: column;
    width: 100%;
}
.Recaptcha {
    position: relative;
    left: -10px;
    margin-top: 2rem;
    max-width: 100%;
}

.Actions {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
}

.Button {
    margin: 3rem 0 0 auto;
    display: flex;
    align-self: center;
    height: 48px;
    border-radius: 6px;
    border-width: 0;
    padding: 16px 32px;
    box-sizing: border-box;
    font-weight: bold;
    text-decoration: none;
    color: #fff;
    background-color: rgb(75, 80, 175);
    align-items: center;
    font-size: 16px;
}

.Back {
    margin-right: auto;
    margin-left: 0;
}

.Button:disabled {
    opacity: 0.3;
}


@media all and (min-width: 480px) {
    .Recaptcha {
        left: 0;
        margin-left: auto;
        margin-right: 0;
    }
}
@media all and (min-width: 768px) {
    .Label {
        transform: translate(0, 1rem) scale(1.5);
    }

    input:focus + .Label,
    input:active + .Label,
    .LabelWithValue {
        font-size: 0.875rem;
        transform: translate(0, -2rem);
    }

    .FieldRadioItem {
        font-size: 0.875rem;
    }

    .FieldCheckbox ~ .Label,
    .FieldRadio ~ .Label {
        font-size: 0.875rem;
    }

    .RadioFieldContainer {
        width: 4rem;
        height: 3rem;
        flex: 0 0 4rem;
    }

    .RadioStyle {
        width: 1.5rem;
        height: 1.5rem;
    }

    .CheckboxLabel {
        font-size: 1rem;
    }

    .FieldSecondaryField {
        font-size: 1rem;
    }
}

@media all and (min-width: 1024px) {
    .Label {
        font-size: 1rem;
    }


    input:focus + .Label,
    input:active + .Label,
    .LabelWithValue {
        font-size: 0.875rem;
        transform: translate(0, -2rem);
    }


    .FieldRadioItem {
        font-size: 1rem;
    }
}
