.Result {
    display: flex;
    flex-flow: column;
    margin: auto;
    justify-content: center;
}

.Description {
    max-width: 80vw;
    margin: auto;
    line-height: 1.2;
    overflow: auto;
    font-size: 20px;
}

.Title {
    justify-content: center;
    font-weight: 400;
    display: inline;
}

.Zone {
    display: flex;
    justify-content: center;
    margin: 1rem auto;
}

.Back {
    display: flex;
    align-self: center;
    outline: none;
    height: 48px;
    border-radius: 6px;
    border-width: 0;
    padding: 8px 16px;
    box-sizing: border-box;
    font-weight: bold;
    margin: 4rem auto 0;
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    align-items: center;
    background-color: rgb(75, 80, 175);
}
.Table {
    margin: auto;
    width: 100%;
    font-size: 12px;
    margin-top: 2rem;
    background: #ffffff;
    padding-bottom: 1rem;
}

.Table thead tr th,
.Table tbody tr td {
    height: 32px;
    color: #333;
    padding: 0.5rem;
}

.Table tr th,
.Table tbody tr:not(:last-child) td {
    border-bottom: 1px solid #333;
}

.Logo {
    padding: 2rem 1rem 0rem 1rem;
    border-top: 1px solid #333;
}

.AuthorBlock {
    display: flex;
    flex-flow: column;
    margin: 0 auto;

}
.Author {
    display: flex;
    color: #333;
    align-self: center;
    height: 1rem;
    align-items: center;
    line-height: 0.5;
}
.Author:first-child {
    border-top: 1px solid #333;
}


@media all and (min-width: 768px) {
    .Table {
        font-size: 14px;
    }
}
