.App {
    display: flex;
    text-align: center;
    flex-flow: column;

    min-height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.Body {
    display: flex;
    height: 100vh;
    width: 100%;
    flex-flow: column;
}
