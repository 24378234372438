.Button {
    display: flex;
    align-self: center;
    outline: none;
    height: 66px;
    border-radius: 6px;
    border-width: 0;
    padding: 16px 16px;
    box-sizing: border-box;
    font-weight: bold;
    margin: 2rem auto;
    text-decoration: none;
    color: #fff;
    background-color: rgb(75, 80, 175);
    align-items: center;
}

.Intro {
    display: flex;
    width: 50vw;
    min-width: 300px;
    flex-flow: column;
    margin: auto;
}

@media all and (max-width: 768px) {
    .Button {
        padding: 8px;
        height: 48px;
    }
}
