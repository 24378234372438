.Footer {
  display: flex;
  margin-top: auto;
  margin-left: auto;
  margin-bottom: 1rem;
  margin-right: 1rem;
  flex-flow: column;
}

.Progress,
.CurrentProgress {
  height: 1rem;
  border-radius: 25px;
}

.CurrentProgress {
  height: 100%;
  display: flex;
  background: blue;
}
.Progress {
  width: 200px;
  background: grey;
}

.Indicator {
  display: flex;
  font-size: 0.875rem;
  text-align: center;
  justify-content: center;
  margin-bottom: 0.5rem;
}
